import { useContext, useEffect, useRef, useState } from "react";
import { OrderContext } from "../../../contexts/OrderContext";

function Payment() {
    const { userData, isAddressConfirmed, total, placeOrder } = useContext(OrderContext);
    const [isButtonRendered, setIsButtonRendered] = useState(false);
    const paypalButtonRef = useRef();

    useEffect(() => {
        if (isAddressConfirmed && total > 0 && window.paypal && !isButtonRendered) {
            window.paypal.Buttons({
                createOrder: function (data, actions) {
                    return actions.order.create({
                        purchase_units: [{
                            amount: {
                                value: total.toString(),
                            },
                            description: 'Halal Food HK Payment',
                        }]
                    });
                },
                onApprove: function (data, actions) {
                    return actions.order.capture().then(function (details) {
                        placeOrder(details.id);
                    });
                }
            }).render(paypalButtonRef.current);

            setIsButtonRendered(true);
        }
    }, [isAddressConfirmed, total, placeOrder, isButtonRendered]);

    return (
        <div className="detail-row">
            <div className="detail-step">
                <span><i className="icon far fa-credit-card" /> Payment</span>
                {(!userData.phone || !userData.code || !isAddressConfirmed) && <span className="icon far fa-lock" />}
            </div>
            {isAddressConfirmed && (
                <div className="detail-body">
                    <div ref={paypalButtonRef}></div>
                    <span onClick={() => placeOrder('test')}> Order </span>
                </div>
            )}
        </div>
    );
}

export default Payment;