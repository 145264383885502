import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'

import { BASE_API_URL } from '../../utils/Constants'
import bgone from '../../assets/images/background/bg-5.png'
import Bgtwo from '../../assets/images/background/bg-6.png'

function MenuSection() {
    const [activeTab, setActiveTab] = useState(1);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get(BASE_API_URL + 'menus')
            .then(response => {
                setData(response.data)
                setLoading(false);
            })
            .catch(error => {
                console.error(error)
                setLoading(false)
            });
    }, []);

    return (
        <>
            {!loading && <section className="menu-section">

                <div className="left-bg"><img src={bgone} alt="" title="" /></div>

                <div className="right-bg"><img src={Bgtwo} alt="" title="" /></div>

                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle"><span>Wide selection</span></div>

                        <div className="pattern-image">
                            <img src={require('../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                        </div>

                        <h2>Delicious Menu</h2>
                    </div>

                    <div className="tabs-box menu-tabs">
                        <div className="buttons">
                            <ul className="tab-buttons clearfix">
                                {data.map((item, index) => <li key={index} className={`tab-btn ${activeTab === item.id && "active-btn"}`} onClick={() => setActiveTab(item.id)} data-tab={'#tab-' + item.id}><span>{item.name}</span></li>)}
                            </ul>
                        </div>
                        <div className="tabs-content">
                            {data.map((menu, index) => <div key={index} className={`tab ${activeTab === menu.id && "active-tab"}`} id={'tab-' + menu.id}>
                                <div className="row clearfix">
                                    <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                                        <div className="inner">
                                            {menu.menu_items.map((menuItem, index) => (
                                                index < menu.menu_items.length / 2 && (
                                                    <div key={index} className="dish-block">
                                                        <div className="inner-box">
                                                            <div className="dish-image"><Link to="#"><img src={BASE_API_URL + menuItem.image_url} alt="" /></Link></div>
                                                            <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">{menuItem.name}</Link></h5></div><div className="price"><span>{'HK$' + menuItem.price}</span></div></div>
                                                            <div className="text desc">{menuItem.description}</div>
                                                        </div>
                                                    </div>
                                                )
                                            ))}
                                        </div>
                                    </div>
                                    <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                                        <div className="inner">
                                            {menu.menu_items.map((menuItem, index) => (
                                                index >= menu.menu_items.length / 2 && (
                                                    <div key={index} className="dish-block">
                                                        <div className="inner-box">
                                                            <div className="dish-image"><Link to="#"><img src={BASE_API_URL + menuItem.image_url} alt="" /></Link></div>
                                                            <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">{menuItem.name}</Link></h5></div><div className="price"><span>{'HK$' + menuItem.price}</span></div></div>
                                                            <div className="text desc">{menuItem.description}</div>
                                                        </div>
                                                    </div>
                                                )
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>)}
                        </div>
                    </div>

                    <div className="open-timing">
                        <div className="hours">Open Daily <span className="theme_color">11:00 - 15:00</span> and <span className="theme_color">18:00 - 23:00</span></div>
                        <div className="link-box">
                            <Link to="/menu" className="theme-btn btn-style-two clearfix">
                                <span className="btn-wrap">
                                    <span className="text-one">Start Ordering</span>
                                    <span className="text-two">Start Ordering</span>
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>

            </section>}
        </>
    )
}

export default MenuSection