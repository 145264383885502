import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Img from '../../assets/images/background/order-bg.jpg';
import { ReactComponent as Trash } from '../../assets/images/icons/trash.svg';
import { OrderContext } from '../../contexts/OrderContext';
import Bredcom from '../Bredcrumb/Main';
import WhyUs from '../Home/WhyUs';
import OrderDetails from './OrderDetails/Main';

function Main() {
    const navigate = useNavigate();

    const { 
        orderItems,
        deleteFromOrder,
        isSelfPickup,
        isAddressConfirmed,
        deliveryFee,
        discount,
        subTotal,
        total
    } = useContext(OrderContext);

    useEffect(() => {
        if(orderItems.length === 0) navigate('/menu');
    }, [navigate, orderItems.length])

    return <>
        <Bredcom subtitle="Your Order" title="order summary" Img={Img} />
        <section className="order-section">
            <div className="order-summary">
                <div className="order-items">
                    {orderItems.map((item, index) => <div key={index} className='item-row'>
                        <div className="item-name">{item.name}</div>
                        <div className="item-quantity">{item.quantity}</div>
                        <div className="item-price">HK${item.price * item.quantity}</div>
                        {!isAddressConfirmed && <div><Trash className="item-action" onClick={() => deleteFromOrder(index)} /></div>}
                    </div>)}
                    <hr />
                    <div className='info-row'>
                        <div className='info-name'>Sub Total:</div>
                        <div className='info-amount'>HK${subTotal}</div>
                    </div>
                    {discount > 0 && <div className='info-row'>
                        <div className='info-name'>Discount</div>
                        <div className='info-amount'>- HK${discount}</div>
                    </div>}
                    {!isSelfPickup && <div className='info-row'>
                        <div className='info-name'>Delivery Fee:</div>
                        <div className='info-amount'>HK${deliveryFee}</div>
                    </div>}
                    <div className='info-row'>
                        <div className='info-name'>Total:</div>
                        <div className='info-amount'>HK${total}</div>
                    </div>
                </div>
            </div>
            <OrderDetails />
        </section>
        <WhyUs />
    </>
}

export default Main