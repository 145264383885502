import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import logonew from '../../assets/images/logo.png'

function Main() {
    const [lastscrool, setlastscrool] = useState(0);

    const [scrolled, setScrolled] = useState(false);
    const [Nav, setNav] = useState(true);

    const handleScroll = () => {

        const offset = window.scrollY;
        setlastscrool(offset);

        if (offset > 1000 && offset < lastscrool) {
            setNav(true);
            setScrolled(true);
        }

        else if (offset > 1000) {
            setNav(false)
        }

        else if (offset > 200) {
            setNav(true);
            setScrolled(true);
        }

        else {
            setScrolled(false);
            setNav(true);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
    })

    return (
        <>
            {Nav &&
                <header className={`main-header ${scrolled && "fixed-header"} header-down`}>
                    <div className="header-top">
                        <div className="auto-container">
                            <div className="inner clearfix">
                                <div className="top-left clearfix">
                                    <ul className="top-info clearfix">
                                        <li><i className="icon far fa-map-marker-alt"></i> Pick-up from Jordan MTR Station</li>
                                        <li><i className="icon far fa-clock"></i> Daily : 11:00 - 15:00 and 18:00 - 23:00</li>
                                    </ul>
                                </div>
                                <div className="top-right clearfix">
                                    <ul className="top-info clearfix">
                                        <li><Link to="tel:+85295201170"><i className="icon far fa-phone"></i> +852 9520 1170 </Link> </li>
                                        <li><Link to="mailto:halalfoodhk786@gmail.com"><i className="icon far fa-envelope"></i> halalfoodhk786@gmail.com</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="header-upper header-fixed" >
                        <div className="auto-container">
                            <div className="main-box d-flex justify-content-center align-items-center flex-wrap">

                                <div className="logo-box">
                                    <div className="logo"><Link to="/" title="Halal Food HK"><img src={logonew} alt="" title="Halal Food HK" /></Link></div>
                                </div>
                    
                            </div>
                        </div>
                    </div>
                </header>
            }
        </>
    )
}

export default Main