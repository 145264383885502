import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import Img from '../../assets/images/background/menu-bg.jpg';
import OrderIcon from '../../assets/images/icons/order.png';
import { OrderContext } from '../../contexts/OrderContext';
import { BASE_API_URL } from '../../utils/Constants';
import Bredcom from '../Bredcrumb/Main';
import BannerMenu from './BannerMenu';
import ListMenu from './ListMenu';
import Loading from './Loading';

function Main() {
  const { count } = useContext(OrderContext);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get(BASE_API_URL + 'menus/full')
      .then(response => {
        setData(response.data)
        setLoading(false)
      })
      .catch(error => {
        console.error(error)
        setLoading(false)
      });
  }, []);

  return (
    <>
      <Bredcom subtitle="Full Menu" title="DELICIOUS & AMAZING" Img={Img} />
      {loading ? <Loading /> : data.map((menu, index) => menu.menu_items.length > 4 ?
        <ListMenu key={index} menu={menu} />
        : <BannerMenu key={index} menu={menu} />)}
      {
        count > 0 && <div className="cart-btn">
          <Link to="/order" className="theme-btn">
            <span className="icon">
              <img src={OrderIcon} alt="" title="" />
            </span>
            <span className="txt">your order ({count})</span>
          </Link>
        </div>
      }
    </>
  )
}

export default Main

