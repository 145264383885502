import React from 'react'
import SpecialMenu from './SpecialMenu'
import Banner from './Banner'
import Offer from './Offer'
import OurStory from './OurStory'
import SpecialDish from './SpecialDish'
import MenuSection from './MenuSection'
import WhyUs from './WhyUs'


function Main() {
    return (
        <>
            <Banner />
            <Offer />
            <OurStory />
            <SpecialDish />
            <MenuSection />
            <SpecialMenu />
            <WhyUs />
        </>
    )
}

export default Main