import React, { createContext, useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { BASE_API_URL } from '../utils/Constants';

export const OrderContext = createContext();

export const OrderProvider = ({ children, triggerSnackbar }) => {
    const navigate = useNavigate();

    const [addresses, setAddresses] = useState([]);
    const [userData, setUserData] = useState({ phone: '', first_name: '', last_name: '', code: '' });

    const [orderItems, setOrderItems] = useState([]); // name, price, quantity, instructions
    const [isSelfPickup, setIsSelfPickup] = useState(false);
    const [isAddressConfirmed, setIsAddressConfirmed] = useState(false);
    const [addressId, setAddressId] = useState();
    const [district, setDistrict] = useState();

    const [deliveryFee, setDeliveryFee] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [total, setTotal] = useState(0);

    const getSubtotal = useCallback(() => {
        return orderItems.reduce((total, item) => total + item.price * item.quantity, 0);
    }, [orderItems]);

    useEffect(() => {
        if (district && !isSelfPickup) {
            updateDeliveryFee(district, getSubtotal());
        } else {
            setDeliveryFee(0); // No delivery fee if self-pickup
        }
    }, [district, isSelfPickup, getSubtotal]);

    // Update the delivery fee based on district and subtotal
    const updateDeliveryFee = (district, subTotal) => {
        if (subTotal >= 1000) {
            setDeliveryFee(0); // Free delivery for subtotal >= 1000
            return;
        }

        switch (district) {
            case 'Kowloon':
            case '九龍':
                setDeliveryFee(50);
                break;
            case 'Hong Kong Island':
            case '香港島':
                setDeliveryFee(70);
                break;
            case 'New Territories':
                setDeliveryFee(120);
                break;
            default:
                setDeliveryFee(120);
                break;
        }
    };

    useEffect(() => {
        const subTotal = getSubtotal();
        const discountAmount = isSelfPickup ? Math.round(subTotal * 0.20) : 0; // 20% discount for self-pickup
        setDiscount(discountAmount);

        const totalAmount = subTotal - discountAmount + deliveryFee;
        setTotal(totalAmount);
    }, [isSelfPickup, getSubtotal, deliveryFee]);

    const addToOrder = (newItem) => {
        setOrderItems((prevItems) => {
            const itemIndex = prevItems.findIndex(item => item.name === newItem.name);

            if (itemIndex !== -1) {
                const updatedItems = [...prevItems];
                updatedItems[itemIndex] = {
                    ...updatedItems[itemIndex],
                    quantity: updatedItems[itemIndex].quantity + newItem.quantity,
                };
                triggerSnackbar(`${newItem.name} quantity increased!`);
                return updatedItems;
            }
            triggerSnackbar(`${newItem.name} added to the order!`);
            return [...prevItems, newItem];
        });
    };

    const removeFromOrder = (itemId) => {
        setOrderItems((prevItems) => {
            return prevItems
                .map(item => {
                    if (item.id === itemId) {
                        return {
                            ...item,
                            quantity: item.quantity - 1,
                        };
                    }
                    return item;
                })
                .filter(item => item.quantity > 0);
        });
    };

    const deleteFromOrder = (index) => {
        setOrderItems((prevItems) => {
            const updatedItems = [...prevItems];
            updatedItems.splice(index, 1);
            return updatedItems;
        });
    };

    const getOrderCount = () => {
        return orderItems.reduce((total, item) => total + item.quantity, 0);
    };

    const count = getOrderCount();
    const subTotal = getSubtotal();

    const placeOrder = async (payment) => {
        triggerSnackbar('Placing order...');
        const order = {
            is_pickup: isSelfPickup,
            address_id: !isSelfPickup ? addressId : null,
            sub_total: subTotal,
            delivery_fee: deliveryFee,
            donations: 0,
            discount,
            total,
            payment,
            order_items: orderItems,
        };
        try {
            const response = await axios.post(`${BASE_API_URL}orders/add`, {
                phone: userData.phone,
                code: userData.code,
                order,
            });
            triggerSnackbar(response.data.success);
            navigate('/');
        } catch (err) {
            triggerSnackbar(err.response?.data?.error || 'An error occurred');
        } finally {
            setOrderItems([]);
            setUserData({ phone: '', first_name: '', last_name: '', code: '' });
            setAddresses([]);
            setIsSelfPickup(false);
            setIsAddressConfirmed(false);
            setAddressId(undefined);
            setDistrict(undefined);
            setDeliveryFee(0);
            setDiscount(0);
            setTotal(0);
        }
    };

    const value = {
        orderItems,
        count,
        subTotal,
        deliveryFee,
        discount,
        total,
        userData,
        addresses,
        addressId,
        district,
        isAddressConfirmed,
        isSelfPickup,
        addToOrder,
        removeFromOrder,
        deleteFromOrder,
        setUserData,
        setAddresses,
        setAddressId,
        setDistrict,
        setIsAddressConfirmed,
        setIsSelfPickup,
        placeOrder
    };

    return (
        <OrderContext.Provider value={value}>
            {children}
        </OrderContext.Provider>
    );
};
